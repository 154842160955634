<template>
  <!-- 资产类别 -->
  <div
    v-loading.fullscreen.lock="isLoading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="box-card" style="text-align: left;margin-top: 20px;">
      <div class="content_box">
        <le-card title="项目" style="width:20%; height: 780px;">
          <div class="left">
          <el-form
            :inline="true"
            size="mini"
            label-position="right"
            class="miniForm"
            :model="treeDataQueryFrom"
          >
            <el-form-item>
              <el-input
                placeholder="请输入名称"
                v-model="treeDataQueryFrom.filterText"
                maxlength="15"
                @input="(e) => (treeDataQueryFrom.filterText = validSpace(e))"
              ></el-input>
            </el-form-item>
            <el-form-item v-if="this.getUserInfo.user_name === 'admin'">
              <el-select
                popper-class="my-select"
                v-model="treeDataQueryFrom.group_id"
                filterable
                clearable
                default-first-option
                placeholder="请选择集团"
                @change="fnGetAssetCategoryTreeList()"
              >
                <el-option
                  v-for="item in allGroupList"
                  :key="item._id"
                  :label="item.group_name"
                  :value="item._id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-tree
            :data="treeData"
            accordion
            :filter-node-method="filterNode"
            node-key="id"
            :props="customProps"
            ref="treeDataRef"
            :default-expanded-keys="defaultExpandedKeys"
            :expand-on-click-node="false"
            @node-click="categoryTreeClick"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span :style="data.id == bindId ? 'color:#409EFF;' : ''">
                {{ node.label }}</span
              >
              <div class="icon_box">
                <i
                  class="iconfont lebo-xinzeng"
                  @click.prevent.stop="openAddCategoryDialog(node, data)"
                  v-if="
                    getUserInfo.user_name != 'admin' &&
                    isShowBtn('4b9bbb2a579a49e4941e375e') &&
                    node.level < 10
                  "
                ></i>
                <i
                  class="iconfont lebo-shanchu1"
                  @click.prevent.stop="delCategory(node, data, null)"
                  v-if="
                    node.level != 1 && isShowBtn('5522f6ec780342508e2f80cc')
                  "
                ></i>
                <i
                  class="iconfont lebo-daoru1"
                  @click.prevent.stop="
                    openConfigImportDialog(node, data, node.level)
                  "
                  v-if="
                    node.level != 1 &&
                    isShowBtn('e39ce41e0e144860a09dc151') &&
                    node.level < 10
                  "
                ></i>
              </div>
            </span>
          </el-tree>
        </div>
        </le-card>
        <!-- <div class="vertical_box">
          <el-divider direction="vertical"></el-divider>
        </div> -->
        <div class="right">
          <le-card title="类别" style="height: 230px; margin-bottom: 20px;">
            <el-table
            :data="categoryData"
            tooltip-effect="dark"
            style="width: 100%"
            border
            stripe
            height="170"
          >
            <el-table-column prop="name" label="类别名称"></el-table-column>
            <el-table-column label="操作" width="180" v-if="!isProject">
              <template slot-scope="scope">
                <lbButton
                  type="warning"
                  icon="bianji"
                  hint="编辑"
                  @click="openUpdateCategoryDialog(scope.row)"
                  v-if="isShowBtn('370fee495a564ad480e54c58')"
                ></lbButton>
                <lbButton
                  type="err"
                  icon="shanchu1"
                  hint="删除"
                  @click="delCategory(null, scope.row)"
                  v-if="isShowBtn('5522f6ec780342508e2f80cc')"
                ></lbButton>
              </template>
            </el-table-column>
          </el-table>
          </le-card>
          <le-card title="巡检项" style="height: 530px; ">
            <template slot="heard-right">
              <div class="btn_box">
            <lbButton
              icon="xinzeng"
              @click="openAddInspectionItemDialog"
              v-if="
                this.getUserInfo.user_name != 'admin' &&
                isShowBtn('3911c6fd26994502ad8a1561')
              "
              >新增</lbButton
            >
            <lbButton
              type="info"
              icon="daoru1"
              @click="openImportDialog"
              v-if="isShowBtn('a28a0465932a4f9aaef55c9e')"
              >从模板导入</lbButton
            >
          </div>
            </template>
          <el-table
            class="tablestyle"
            :data="inspectionItemData"
            tooltip-effect="dark"
            style="width: 100%"
            border
            stripe
            height="430"
          >
            <el-table-column
              prop="inspection_name"
              label="巡检项名称"
            ></el-table-column>
            <el-table-column
              prop="inspection_content"
              label="检查内容"
              min-width="200"
            >
              <template slot-scope="scope">
                <div>
                  <span
                    v-html="
                      scope.row.inspection_content.replace(
                        /\r\n|\n|\r/g,
                        '<br/>'
                      )
                    "
                  ></span>
                  <el-link
                    type="primary"
                    v-if="scope.row.pic.length > 0"
                    @click="showPreviewPicture(scope.row.pic)"
                    >【查看】</el-link
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <lbButton
                  type="warning"
                  icon="bianji"
                  hint="编辑"
                  @click="openUpdateInspectionItemDialog(scope.row)"
                  v-if="isShowBtn('20bf4f297c684a239cc051a2')"
                ></lbButton>
                <lbButton
                  type="err"
                  icon="shanchu1"
                  hint="删除"
                  @click="delUpdateInspectionItem(scope.row)"
                  v-if="isShowBtn('4d8f4ee4b39840d5bbc22eac')"
                ></lbButton>
              </template>
            </el-table-column>
          </el-table>
          </le-card>
        </div>
      </div>
    </div>
    <!-- 新增、编辑类别对话框 -->
    <lebo-dialog
      :title="titleCategoryDialog"
      :isShow="isCategoryDialog"
      width="30%"
      @close="categoryDialogClose" footerSlot
    >
      <el-form
        :model="categoryForm"
        :rules="categoryFormRules"
        ref="categoryFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="上一级："
          v-if="titleCategoryDialog == '新增类别'"
          >{{ categoryForm.parent_name }}</el-form-item
        >
        <el-form-item label="类别名称：" prop="category_name">
          <el-input
            v-model="categoryForm.category_name"
            maxlength="15"
            placeholder="请输入类别名称"
            @input="(e) => (categoryForm.category_name = validSpace(e))"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="isCategoryDialog = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="fnSendCategoryForm">保 存</lbButton>
      </span>
    </lebo-dialog>
    <!-- 新增、编辑巡检项对话框 -->
    <lebo-dialog
      :title="titleInspectionItemDialog"
      :isShow="isInspectionItemDialog"
      width="40%"
      @close="inspectionItemDialogClose" footerSlot
    >
      <el-form
        :model="inspectionItemForm"
        :rules="inspectionItemFormRules"
        ref="inspectionItemFormRef"
        label-width="120px"
      >
        <el-form-item label="巡检项名称：" prop="inspection_name">
          <el-input
            v-model="inspectionItemForm.inspection_name"
            maxlength="30"
            placeholder="请输入巡检项名称"
            @input="(e) => (inspectionItemForm.inspection_name = validSpace(e))"
          ></el-input>
        </el-form-item>
        <el-form-item label="检查内容：" prop="inspection_content">
          <el-input
            type="textarea"
            maxlength="300"
            v-model="inspectionItemForm.inspection_content"
            :rows="6"
            placeholder="请输入检查内容"
            @blur="
              inspectionItemForm.inspection_content =
                inspectionItemForm.inspection_content
                  .replace(/\r\n|\n|\r/g, '<br/>')
                  .replace(/\s/g, '')
                  .replace(/<br\/>/g, '\n')
            "
          ></el-input>
        </el-form-item>
        <el-form-item label="示意图：" class="img_content">
          <ul v-if="inspectionItemForm.pic.length > 0" class="img_box">
            <li v-for="(item, index) in inspectionItemForm.pic" :key="index">
              <div
                style="
                  width: inherit;
                  height: inherit;
                  overflow: hidden;
                  border-radius: 5px;
                "
              >
                <el-image
                  :src="item"
                  :preview-src-list="inspectionItemForm.pic"
                  :z-index="9999"
                  fit="cover"
                >
                </el-image>
              </div>
              <span class="close_box" @click="delImg(index)">
                <i class="el-icon-close"></i>
              </span>
            </li>
          </ul>
          <div
            class="upload_box"
            v-if="
              inspectionItemForm.pic.length >= 0 &&
              inspectionItemForm.pic.length < 3
            "
            @click="uploadImg"
          >
            <i class="el-icon-plus"></i>
          </div>
          <input
            type="file"
            name="file"
            accept="image/*"
            ref="uploadImageRef"
            id="uploadImage"
            v-show="false"
            @change="uploadImageChange"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="isInspectionItemDialog = false">返 回</lbButton> -->
        <lbButton type="warning" fill icon="back" @click="fnSendInspectionItemForm(true)">
          另存为模板
        </lbButton>
        <lbButton type="default" fill icon="confirm" @click="fnSendInspectionItemForm(false)">
          保 存
        </lbButton>
      </span>
    </lebo-dialog>
    <!-- 导入模板对话框 -->
    <ImportDialog
      v-if="isImportDialog"
      @myClose="myClose"
      :projectId="projectId"
      :bindId="bindId"
      :bindName="bindName"
      @showPreviewPicture="showPreviewPicture"
    ></ImportDialog>
    <!-- 从已配置中导入对话框 -->
    <ConfigImportDialog
      v-if="isConfigImportDialog"
      @myClose="configImportDialogClose()"
      :projectId="projectId"
      :bindId="bindId"
      :bindName="bindName"
      :level="level"
      @loading="isLoading = true"
      @closeLoading="isLoading = false"
    ></ConfigImportDialog>
    <!-- 图片预览 -->
    <el-image-viewer
      v-if="isShowPreviewPicture"
      :on-close="onClosePreviewPicture"
      :url-list="previewPictureList"
      :z-index="9999"
    />
  </div>
</template>

<script>
import {
  getAssetCategoryTreeList,
  addInspectionTemplate,
  getAllGropSelectList,
  addAssetCategory,
  updateAssetCategory,
  delAssetCategory,
  addAssetInspectionItem,
  updateAssetInspectionItem,
  delAssetInspectionItem,
  getAssetInspectionItemList
} from '@/api/assetsManagement'
import request from '@/api/systemapi'
import ConfigImportDialog from './components/configImportDialog/index.vue'
import ImportDialog from './components/ImportDialog/index.vue'
import { mapGetters } from 'vuex'
// // 导入组件
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  components: {
    ConfigImportDialog,
    ImportDialog,
    ElImageViewer
  },
  data () {
    return {
      // 加载状态
      isLoading: false,
      loadingText: '加载中',
      // 树控件查询表单
      treeDataQueryFrom: {
        filterText: '',
        group_id: ''
      },
      // 所有集团下拉列表
      allGroupList: [],
      // 树控件
      treeData: [],
      customProps: {
        label: 'name',
        children: 'children'
      },
      // 展开的树
      defaultExpandedKeys: [],
      isProject: false,
      // 类别列表
      categoryData: [],
      // 巡检项列表
      inspectionItemData: [],
      projectId: '', // 资产项目id
      bindId: '', // 当前节点的id
      // 是否展示新增、编辑类别对话框
      isCategoryDialog: false,
      // 新增、编辑类别对话框标题
      titleCategoryDialog: '',
      // 新增类别表单
      categoryForm: {
        category_name: '',
        category_id: '',
        project_id: '',
        parent_id: '',
        parent_name: ''
      },
      categoryFormRules: {
        category_name: [
          { required: true, message: '请输入类别名称', trigger: 'blur' }
        ]
      },
      // 是否展示新增、编辑巡检项对话框
      isInspectionItemDialog: false,
      titleInspectionItemDialog: '',
      // 是否显示另存为模板按钮
      isTemplateShow: false,
      inspectionItemForm: {
        id: '',
        inspection_name: '',
        inspection_content: '',
        pic: [],
        is_template: false
      },
      inspectionItemFormRules: {
        inspection_name: [
          { required: true, message: '请输入巡检项名称', trigger: 'blur' }
        ],
        inspection_content: [
          { required: true, message: '请输入检查内容', trigger: 'blur' }
        ]
      },
      // 是否展示导入模板对话框
      isImportDialog: false,
      // 是否展示从已配置中导入对话框
      isConfigImportDialog: false,
      // 展示图片预览
      isShowPreviewPicture: false,
      // 预览图片
      previewPictureList: [],
      // 层级
      level: 0,
      // 节点名称
      bindName: ''
    }
  },
  computed: {
    ...mapGetters(['getUserInfo'])
  },
  created () {
    if (this.getUserInfo.user_name === 'admin') {
      this.fnGetAllGropSelectList()
    }
    this.fnGetAssetCategoryTreeList()
  },
  mounted () {},
  watch: {
    'treeDataQueryFrom.filterText' (val) {
      this.$refs.treeDataRef.filter(val)
    }
  },
  methods: {
    // 获取集团下拉列
    async fnGetAllGropSelectList () {
      const res = await getAllGropSelectList()
      this.allGroupList = res && res.Code === 200 ? res.Data : []
    },
    // 获取页面中的树控件
    async fnGetAssetCategoryTreeList () {
      this.isLoading = true
      const res = await getAssetCategoryTreeList({
        group_id: this.treeDataQueryFrom.group_id
      })
      // console.log(res);
      if (res && res.Code === 200 && res.Data && res.Data.length > 0) {
        this.treeData = res.Data
        this.bindId = this.bindId ? this.bindId : res.Data[0].id
        this.bindName = this.bindName ? this.bindName : res.Data[0].name
        this.projectId = this.projectId ? this.projectId : res.Data[0].id
        // 如果类别数据表中没有数据，则默认树结构第一项的数据
        if (this.categoryData.length === 0) {
          this.isProject = res.Data[0].is_project
          this.categoryData.push(res.Data[0])
          res.Data.forEach((item) => {
            if (item.id === this.projectId) {
              // 设置树展开
              this.getDefaultExpandedKeys([item])
            }
          })
        } else {
          // 若有数据，则树结构重新获取数据
          this.getCategoryData(res.Data, this.categoryData[0].id)
        }
        // 获取巡检项列表
        this.fnGetAssetInspectionItemList()
      } else {
        this.treeData = []
        this.categoryData = []
        this.isLoading = false
      }
    },
    // 展开的树的数据
    getDefaultExpandedKeys (data) {
      var that = this
      data.forEach((item) => {
        that.defaultExpandedKeys.push(item.id)
        if (item.children && item.children.length > 0) {
          // console.log('data----', item.children);
          that.getDefaultExpandedKeys(item.children)
        }
      })
    },
    // 类别列表数据
    getCategoryData (data, categoryId) {
      var that = this
      data.forEach((item) => {
        // console.log(item.id);
        if (item.id !== categoryId) {
          if (item.children && item.children.length > 0) {
            that.getCategoryData(item.children, categoryId)
          }
        } else {
          that.categoryData = []
          that.categoryData.push(item)
          that.isProject = item.is_project
        }
      })
    },
    // 获取巡检项列表
    async fnGetAssetInspectionItemList () {
      // var obj;
      // if (this.isProject) {
      //   obj = {
      //     'project_id': this.projectId,
      //   }
      // } else {
      //   obj = {
      //     'bind_id': this.bindId,
      //   }
      // }
      const res = await getAssetInspectionItemList({
        bind_id: this.bindId
      })
      // console.log('获取巡检项列表----', res);
      this.inspectionItemData = res && res.Code === 200 ? res.Data : []
      this.isLoading = false
    },
    // 展开新增类别对话框
    openAddCategoryDialog (node, data) {
      console.log('展开新增类别对话框-- node----', node)
      console.log('展开新增类别对话框-- data----', data)
      this.isCategoryDialog = true
      this.titleCategoryDialog = '新增类别'
      this.defaultExpandedKeys = []
      // 设置树展开
      this.getDefaultExpandedKeys([data])
      var that = this
      this.$nextTick(() => {
        that.categoryForm.parent_id = data.id
        that.categoryForm.project_id = data.is_project
          ? data.id
          : data.project_id
        that.categoryForm.parent_name = data.name
      })
    },
    // 删除当前类别
    delCategory (node, data) {
      // console.log('删除当前类别-- node----', node);
      // console.log('删除当前类别-- data----', data);
      this.$confirm(
        '此操作将永久删除该资产类别，您确定要删除该资产类别吗？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          // console.log('删除类别请求');
          this.fnDelAssetCategory(data.id)
        })
        .catch(() => {
          this.$msg.info('已取消删除')
        })
    },
    // 点击树控件
    categoryTreeClick (data, node, key) {
      this.defaultExpandedKeys = []
      // 设置树展开
      this.getDefaultExpandedKeys([data])
      this.categoryData = []
      // console.log('data---点击树控件------', data);
      // console.log('node---点击树控件------', node);
      // console.log('key---点击树控件------', key);
      // 当前是否是根节点
      this.isProject = data.is_project
      // 刷新当前类别数据表
      this.categoryData.push(data)
      // 保存当前节点id
      this.bindId = data.id
      this.bindName = data.name
      // 保存当前节点所属根节点id
      this.projectId = data.is_project ? data.id : data.project_id
      // 筛选出当前节点所属根节点id下的数据
      var arr = this.treeData.filter((item) => {
        return item.id === this.projectId
      })
      // 根据当前节点获取巡检项
      this.fnGetAssetInspectionItemList()
    },
    // 删除类别请求
    async fnDelAssetCategory (id) {
      this.isLoading = true
      const res = await delAssetCategory({
        Id: id
      })
      // console.log('删除类别请求---', res);
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
        this.fnGetAssetCategoryTreeList()
        this.categoryData = []
        this.bindId = this.projectId
        this.bindName = ''
      }
      this.isLoading = false
    },
    // 展示编辑类别对话框
    openUpdateCategoryDialog (row) {
      console.log(row)
      this.isCategoryDialog = true
      this.titleCategoryDialog = '编辑类别'
      var that = this
      this.$nextTick(() => {
        that.categoryForm.category_name = row.name
        that.categoryForm.category_id = row.id
        that.categoryForm.project_id = row.parent_id
      })
    },
    // 保存新增、编辑类别
    fnSendCategoryForm () {
      this.$refs.categoryFormRef.validate((valid) => {
        if (valid) {
          var obj = {}
          if (this.titleCategoryDialog === '新增类别') {
            obj.category_name = this.categoryForm.category_name
            obj.project_id = this.categoryForm.project_id
            obj.parent_id = this.categoryForm.parent_id
            this.fnAddAssetCategory(obj)
          } else {
            obj.category_name = this.categoryForm.category_name
            obj.Id = this.categoryForm.category_id
            this.fnUpdateAssetCategory(obj)
          }
        } else {
          return false
        }
      })
    },
    // 新增类别请求
    async fnAddAssetCategory (obj) {
      const res = await addAssetCategory(obj)
      if (res && res.Code === 200) {
        this.isCategoryDialog = false
        this.fnGetAssetCategoryTreeList()
      }
    },
    // 编辑类别请求
    async fnUpdateAssetCategory (obj) {
      const res = await updateAssetCategory(obj)
      if (res && res.Code === 200) {
        this.isCategoryDialog = false
        this.fnGetAssetCategoryTreeList()
      }
    },
    // 关闭新增、编辑类别对话框
    categoryDialogClose () {
      this.isCategoryDialog = false
      this.$refs.categoryFormRef.resetFields()
      this.categoryForm.category_id = ''
    },
    // 树节点筛选
    filterNode (value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    // 打开新增巡检项巡检项对话框
    openAddInspectionItemDialog () {
      this.isInspectionItemDialog = true
      this.titleInspectionItemDialog = '新增巡检项'
    },
    // 打开编辑巡检项对话框
    openUpdateInspectionItemDialog (row) {
      console.log('编辑巡检项----', row)
      this.isInspectionItemDialog = true
      this.titleInspectionItemDialog = '编辑巡检项'
      var that = this
      this.$nextTick(() => {
        that.inspectionItemForm.id = row.Id
        that.inspectionItemForm.inspection_name = row.inspection_name
        that.inspectionItemForm.inspection_content = row.inspection_content
        that.inspectionItemForm.pic = row.pic ? row.pic.split(',') : []
      })
    },
    // 删除巡检项
    delUpdateInspectionItem (row) {
      console.log('删除巡检项---', row)
      this.$confirm(
        '此操作将永久删除该巡检项，您确定要删除该巡检项吗？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.fnDelAssetInspectionItem(row.Id)
        })
        .catch(() => {
          this.$msg.info('已取消删除')
        })
    },
    // 删除巡检项请求
    async fnDelAssetInspectionItem (id) {
      const res = await delAssetInspectionItem({
        Id: id
      })
      // console.log('删除巡检项请求----', res);
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
        this.fnGetAssetInspectionItemList()
      }
    },
    // 保存新增、编辑巡检项
    fnSendInspectionItemForm (flag) {
      // flag   是否存为模板
      this.$refs.inspectionItemFormRef.validate((valid) => {
        if (valid) {
          var obj = {}
          obj.inspection_name = this.inspectionItemForm.inspection_name
          obj.inspection_content = this.inspectionItemForm.inspection_content
          obj.pic = this.inspectionItemForm.pic.join(',')
          if (this.titleInspectionItemDialog === '新增巡检项') {
            obj.project_id = this.projectId
            obj.bind_id = this.bindId
            if (flag) {
              this.fnAddInspectionTemplate(obj)
            } else {
              this.fnAddAssetInspectionItem(obj)
            }
          } else {
            obj.Id = this.inspectionItemForm.id
            obj.is_template = flag
            // console.log('编辑巡检项请求');
            this.fnUpdateAssetInspectionItem(obj)
          }
          this.isTemplateShow = false
        } else {
          return false
        }
      })
    },
    // 新增巡检项请求
    async fnAddAssetInspectionItem (obj) {
      const res = await addAssetInspectionItem(obj)
      // console.log('新增巡检项请求-----', res);
      if (res && res.Code === 200) {
        this.isInspectionItemDialog = false
        this.fnGetAssetInspectionItemList()
      }
    },
    //  新增资产巡检项模板
    async fnAddInspectionTemplate (obj) {
      const res = await addInspectionTemplate(obj)
      // console.log('fnAddInspectionTemplate----', res);
      if (res && res.Code === 200) {
        this.$msg.success('另存为模板成功！')
      }
    },
    // 编辑巡检项请求
    async fnUpdateAssetInspectionItem (obj) {
      const res = await updateAssetInspectionItem(obj)
      // console.log('编辑巡检项请求-----', res);
      if (res && res.Code === 200) {
        if (obj.is_template) {
          this.$msg.success('另存为模板成功！')
        } else {
          this.isInspectionItemDialog = false
          this.fnGetAssetInspectionItemList()
        }
      }
    },
    // 关闭新增、编辑巡检项对话框
    inspectionItemDialogClose () {
      this.isInspectionItemDialog = false
      this.$refs.inspectionItemFormRef.resetFields()
      this.inspectionItemForm.id = ''
      this.inspectionItemForm.pic = []
      console.log(this.inspectionItemForm)
    },
    // 打开导入模板对话框， 并获取模板列表
    async openImportDialog () {
      this.isImportDialog = true
    },
    // 展开 从已配置中导入 对话框
    openConfigImportDialog (node, data, level) {
      console.log('data----', data)
      this.projectId = data.project_id
      this.bindId = data.id
      this.level = level // 层级
      this.bindName = data.name
      this.isConfigImportDialog = true
    },
    // 触发选择图片按钮
    uploadImg () {
      this.$refs.uploadImageRef.value = null
      // 上传图片
      this.$refs.uploadImageRef.click()
    },
    // 获取到图片上传
    uploadImageChange (file) {
      this.isLoading = true
      this.loadingText = '上传中'
      var that = this
      console.log('----uploadImageChange---', file)
      // 将图片文件转化成base64格式图片
      var reader = new FileReader()
      var f = document.getElementById('uploadImage').files[0]
      // console.log(f);
      reader.readAsDataURL(f)
      reader.onload = async (e) => {
        // e.target.result  就是从本地读取的图片的base64格式,将它上传给服务器即可
        // 使用axios的post方法上传即可
        var base64image = e.target.result
        // console.log(base64image);
        const res = await request.updataIcon({
          name: f.name,
          strbase64: [base64image]
        })
        var url = ''
        // console.log(res);
        if (res.data && res.data.length > 0) {
          url = res.data[0].FilePath
          this.inspectionItemForm.pic.push(url)
        } else {
          this.$msg.warning('你的图片格式有误请重新选择!')
        }
        this.isLoading = false
        this.loadingText = '加载中'
      }
    },
    // 删除图片
    delImg (index) {
      this.inspectionItemForm.pic.splice(index, 1)
    },
    // 点击查看，预览图片
    showPreviewPicture (pic) {
      this.previewPictureList = pic.split(',')
      this.isShowPreviewPicture = true
    },
    // 关闭预览图片
    onClosePreviewPicture () {
      this.isShowPreviewPicture = false
      this.previewPictureList = []
    },
    // 关闭导入模板对话框
    myClose () {
      this.isImportDialog = false
      this.fnGetAssetCategoryTreeList()
    },
    // 关闭从已配置中导入 对话框
    configImportDialogClose () {
      this.isConfigImportDialog = false
      this.fnGetAssetCategoryTreeList()
    }
  }
}
</script>

<style scoped lang="less">
.title {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #000;
}
.content_box {
  // width: inherit;
  display: flex;
  .left {
    /deep/ .el-tree {
      width: 100%;
      overflow: auto;
      max-height: 750px;
      // .el-tree-node__content{
      //   margin-bottom: 8px;
      // }

    }
    /deep/ .custom-tree-node {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        margin-right: 10px;
      }
      .icon_box {
        display: flex;
        visibility: hidden;
        .iconfont {
          display: block;
          margin-right: 5px;
          font-size: 18px !important;
        }
      }
    }
    /deep/ .custom-tree-node:hover {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        margin-right: 10px;
      }
      .icon_box {
        display: flex;
        visibility: visible;
        .iconfont {
          display: block;
          margin-right: 5px;
          font-size: 18px !important;
        }
      }
    }
  }
  .vertical_box {
    height: inherit;
    padding: 0 10px;
    .el-divider--vertical {
      height: 100%;
    }
  }
  .right {
    margin-left: 20px;
    width: 80%;
    .btn_box {
      margin-bottom: 10px;
    }
  }
}
.img_content {
  /deep/ .el-form-item__content {
    display: flex;
    .upload_box {
      width: 150px;
      width: 150px;
      height: 150px;
      border: 1px solid #dcdfe6;
      background-color: #fbfdff;
      cursor: pointer;
      border-radius: 5px;
      font-size: 24px;
      color: #8c939d;
      text-align: center;
      line-height: 150px;
    }
    .img_box {
      display: flex;
      li {
        width: 150px;
        height: 150px;
        margin-right: 20px;
        cursor: pointer;
        position: relative;
        .el-image {
          width: 100%;
          height: inherit;
        }
        .close_box {
          position: absolute;
          top: -10px;
          right: -10px;
          display: inline-block;
          width: 22px;
          height: 22px;
          text-align: center;
          line-height: 22px;
          border-radius: 50%;
          color: #fff;
          background-color: #606266;
          font-size: 14px;
          cursor: pointer;
          z-index: 9999;
        }
      }
    }
  }
}
// .my_footer {
//   position: relative;
//   /deep/ .my_button {
//     display: inline-block;
//     line-height: 1;
//     white-space: nowrap;
//     cursor: pointer;
//     border: 1px solid #dcdfe6;
//     background-color: #409eff;
//     border-color: #409eff;
//     color: #fff;
//     -webkit-appearance: none;
//     text-align: center;
//     box-sizing: border-box;
//     outline: 0;
//     margin: 0;
//     transition: 0.1s;
//     font-weight: 500;
//     padding: 12px 20px;
//     font-size: 14px;
//     border-radius: 4px;
//     &:hover {
//       background: #66b1ff;
//       border-color: #66b1ff;
//       color: #fff;
//     }
//   }
//   .btn {
//     position: absolute;
//     bottom: -60px;
//     right: 0;
//   }
// }
.miniForm {
  display: flex;
  /deep/ .el-form-item {
    width: 100%;
    .el-form-item__content {
      width: 100%;
    }
  }
  /deep/ .el-input--mini .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
}
.tablestyle {
  /deep/ .el-table__body-wrapper {
    // max-height: 350px;
    overflow-y: auto;
  }
}
</style>
