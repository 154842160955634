<template>
  <div>
    <lebo-dialog title="选择巡检项模板" :isShow="isImportDialog" width="50%" class="import_dialog" @close="importDialogClose" footerSlot>

      <el-table class="tablestyle" :data="templateData" tooltip-effect="dark" border stripe @selection-change="templateDataSelectionChange">
        <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
        <el-table-column prop="inspection_name" label="巡检项名称"></el-table-column>
        <el-table-column prop="inspection_content" label="检查内容" min-width="200">
          <template slot-scope="scope">
            <div>
              <span v-html="scope.row.inspection_content.replace(/\r\n|\n|\r/g, '<br/>')"></span>
              <el-link type="primary" v-if="scope.row.pic.length > 0" @click="showPreviewPicture(scope.row.pic)">【查看】</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="165">
          <template slot-scope="scope">
            <lbButton type="info" icon="daoru1" hint="导入" @click="importTemplate(scope.row.Id)"></lbButton>
            <lbButton type="err" icon="shanchu1" hint="删除" @click="delTemplate(scope.row.Id)"></lbButton>
          </template>
        </el-table-column>
      </el-table>
      <div class="content_box">
        <span>共 {{templateData.length}} 条</span>
        <span class="dialog-footer">
          <lbButton type="info" icon="daoru1" @click="importSelectTemplate">批量导入</lbButton>
          <lbButton type="err" icon="shanchu1" @click="delSelectTemplate">批量删除</lbButton>
        </span>
      </div>
    </lebo-dialog>
  </div>
</template>

<script>
import { getAssetCategoryTreeList, addAssetCategory, updateAssetCategory, delAssetCategory, importConfiguration, addAssetInspectionItem, updateAssetInspectionItem, delAssetInspectionItem, getAssetInspectionItemList, getTemplatePageList, importInspectionTemplate, delInspectionTemplate } from '@/api/assetsManagement'
export default {
  props: ['projectId', 'bindId', 'bindName'],
  data () {
    return {
      // 是否展示导入模板对话框
      isImportDialog: true,
      // 模板列表
      templateData: [],
      templateSelectData: []
    }
  },
  created () {
    this.fnGetTemplatePageList()
    console.log('bindName-----', this.bindName)
  },
  methods: {
    // 获取巡检项模板列表
    async fnGetTemplatePageList () {
      const res = await getTemplatePageList()
      // console.log('获取巡检项模板列表----', res);
      this.templateData = res && res.Code === 200 ? res.Data : []
    },
    // 选中的模板
    templateDataSelectionChange (val) {
      this.templateSelectData = val
    },
    // 删除选中的模板
    delSelectTemplate () {
      if (this.templateSelectData.length > 0) {
        var arr = []
        for (var i = 0; i < this.templateSelectData.length; i++) {
          arr.push(this.templateSelectData[i].Id)
          if (arr.length === this.templateSelectData.length) {
            this.delTemplate(arr.join(','))
          }
        }
      } else {
        this.$msg.warning('请选择要删除的模板！')
      }
    },
    // 删除模板
    delTemplate (ids) {
      this.$confirm('此操作将永久删除该模板，您确定要删除该模板吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // console.log('删除模板请求---', ids);
        this.fndelInspectionTemplate(ids)
      }).catch(() => {
        this.$msg.info('已取消删除')
      })
    },
    // 删除模板请求
    async fndelInspectionTemplate (ids) {
      const res = await delInspectionTemplate({
        Id: ids
      })
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
        this.fnGetTemplatePageList()
      }
      // console.log('删除模板请求-----', res);
    },
    // 导入选中的模板
    importSelectTemplate () {
      if (this.templateSelectData.length > 0) {
        var arr = []
        for (var i = 0; i < this.templateSelectData.length; i++) {
          arr.push(this.templateSelectData[i].Id)
          if (arr.length === this.templateSelectData.length) {
            this.importTemplate(arr.join(','))
          }
        }
      } else {
        this.$msg.warning('请勾选巡检项模板导入！')
      }
    },
    async importTemplate (ids) {
      const res = await importInspectionTemplate({
        Id: ids,
        bind_id: this.bindId,
        project_id: this.projectId,
        inspection_name: this.bindName
      })
      console.log('导入模板请求----res---', res)
      if (res && res.Code === 200) {
        this.importDialogClose()
      }
    },
    // 关闭对话框时触发
    importDialogClose () {
      console.log('关闭对话框时触发---')
      this.$emit('myClose')
      this.isImportDialog = false
    },
    // 预览图片时触发
    showPreviewPicture (pic) {
      console.log('关闭对话框时触发---')
      this.$emit('showPreviewPicture', pic)
    }

  }
}
</script>

<style scoped lang="less">
.import_dialog {
  .content_box {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    height: 50px;
  }
}
.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 350px;
    overflow-y: auto;
  }
}
</style>
