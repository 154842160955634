<template>
  <div>
    <!-- 从已配置中导入对话框 -->
    <lebo-dialog title="请选择类别或巡检项" :isShow="isConfigImportDialog" width="55%" @close="configImportDialogClose" footerSlot>
      <div class="content_box">
        <div class="left_box">
          <div class="title">类别</div>
          <!-- @check-change="checkChange" -->
          <el-tree :data="treeData" check-strictly :render-after-expand="false" ref="treeDataRef" node-key="id" show-checkbox :props="customProps" highlight-current :default-expanded-keys="defaultExpandedKeys" :expand-on-click-node="false" @node-click="categoryTreeClick" @check="treeCheck">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span :style="data.id == categoryId ? 'color:#409EFF;' : ''"> {{ node.label }}</span>
            </span>
          </el-tree>
        </div>
        <div class="vertical_box">
          <el-divider direction="vertical"></el-divider>
        </div>
        <div class="right_box">
          <div class="title">巡检项</div>
          <el-table class="tablestyle" :data="configData" ref="configDataRef" tooltip-effect="dark" style="width: 100%" border stripe @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
            <el-table-column prop="inspection_name" label="巡检项名称"></el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="configImportDialogClose">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="sendSave">确定批量新增</lbButton>
      </span>
    </lebo-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { getAssetCategoryTreeList, addAssetCategory, updateAssetCategory, delAssetCategory, importConfiguration, addAssetInspectionItem, updateAssetInspectionItem, delAssetInspectionItem, getAssetInspectionItemList, getTemplatePageList, importInspectionTemplate, delInspectionTemplate } from '@/api/assetsManagement'
export default {
  name: 'ConfigImportDialog',
  props: ['projectId', 'bindId', 'level', 'bindName'],
  data () {
    return {
      // 是否展示对话框
      isConfigImportDialog: true,
      // 是否是资产项目节点
      isProject: false,
      // 当前巡检项列表所属类别
      categoryId: '',
      // 巡检项列表
      configData: [],
      configSelectData: [],
      // 树控件
      treeData: [],
      customProps: {
        label: 'name',
        children: 'children'
      },
      // 默认展开的树控件
      defaultExpandedKeys: [],
      // 选中的树列表
      checkedList: [],
      childLevel: 0
    }
  },
  created () {
    this.childLevel = this.level
    this.fnGetAssetCategoryTreeList()
  },
  watch: {
  },
  methods: {
    // 获取树控件
    async fnGetAssetCategoryTreeList () {
      this.$emit('loading')
      this.defaultExpandedKeys = []
      const res = await getAssetCategoryTreeList()
      // console.log('获取树控件----', JSON.stringify(res.Data));
      if (res && res.Code === 200) {
        this.treeData = res.Data
        // 默认展开第一个根节点的数据
        this.defaultExpandedKeys.push(res.Data[0].id)
        this.getDefaultExpandedKeys(res.Data[0].children)
        // 默认高亮第一个根节点
        this.categoryId = res.Data[0].id
        this.isProject = res.Data[0].is_project
        // 获取第一个根节点下的巡检项
        this.configData = await this.fnGetAssetInspectionItemList(res.Data[0].id)
        this.$emit('closeLoading')
      } else {
        this.$emit('closeLoading')
        this.treeData = []
      }
    },
    // 展开的树的数据
    getDefaultExpandedKeys (data) {
      var that = this
      data.forEach((item) => {
        that.defaultExpandedKeys.push(item.id)
        if (item.children && item.children.length > 0) {
          // console.log(item.children);
          that.getDefaultExpandedKeys(item.children)
        }
      })
    },
    // 获取巡检项列表
    async fnGetAssetInspectionItemList (id) {
      // var obj;
      // if (this.isProject) {
      //   obj = {
      //     'project_id': id,
      //   }
      // } else {
      //   obj = {
      //     'bind_id': id,
      //   }
      // }
      const res = await getAssetInspectionItemList({
        bind_id: id
      })
      if (res && res.Code === 200) {
        return res.Data
      } else {
        this.configData = []
        this.$emit('closeLoading')
      }
    },
    // 点击树控件
    async categoryTreeClick (data, node, key) {
      console.log('data---点击树控件------', data.is_project)
      // console.log('node---点击树控件------', node);
      // console.log('key---点击树控件------', key);
      this.$emit('loading')
      this.isProject = data.is_project
      // 展开当前点击的树控件
      this.defaultExpandedKeys.push(data.id)
      this.getDefaultExpandedKeys(data.children)
      this.defaultExpandedKeys = [...new Set(this.defaultExpandedKeys)]
      // 获取当前点击的节点下的巡检项
      this.categoryId = data.id
      this.configData = await this.fnGetAssetInspectionItemList(data.id)
      // 若已选中的巡检项列表中存在数据
      if (this.checkedList.length > 0) {
        // 筛选出属于当前巡检项的数据
        var arr = this.checkedList.filter((check) => {
          return check.id === data.id
        })
        if (arr.length === 1) {
          // 若筛选出的数据不为空
          if (arr[0].list.length > 0) {
            // 1、遍历当前所有的巡检项数据，
            // 2、再遍历筛选出的巡检项数据，
            // 3、判断两者id是否相等，
            // 4、若相等，则选中id相等的数据
            this.configData.forEach((item) => {
              arr[0].list.forEach((li) => {
                if (li.Id === item.Id) {
                  this.$nextTick(() => {
                    this.$refs.configDataRef.toggleRowSelection(item, true)
                  })
                }
              })
            })
          }
        }
      }
      this.$emit('closeLoading')
    },
    // 当树控件的选中状态发生改变时触发
    async checkChange (data, node, key) {
      // // 展开当前选中的树节点
      // console.log('data---选中树控件------', data.name, '****', data.id);
      // console.log('node---选中树控件------', node);
      // // // console.log('key---选中树控件------', key);
      // console.log('----结束-------', this.$refs.treeDataRef.getCheckedKeys());
      // console.log('***************************************************');
      // this.$emit('loading')
      // var that = this;
      // if (node) { // 选中
      //   if (this.categoryId === data.id) {
      //     // 若选中的是当前展示的表格，则选中所有的选中项
      //     if (this.configData.length > 0) {
      //       that.$nextTick(() => {
      //         that.$refs.configDataRef.toggleAllSelection();
      //       })
      //     }
      //   } else {
      //     // 若选中的不是当前展示的表格，则获取当前节点下巡检项并全选
      //     this.configData = await this.fnGetAssetInspectionItemList(data.id);
      //     this.categoryId = data.id
      //     // 将当前获取的巡检项数据保存已选中数据列表中
      //     this.checkedList.push({
      //       id: this.categoryId,
      //       list: this.configData
      //     })
      //     // 设置全选状态
      //     that.$nextTick(() => {
      //       that.$refs.configDataRef.toggleAllSelection();
      //     })
      //   }
      //   // that.$emit('closeLoading')
      // } else { // 取消选中
      //   // 1、判断是否有已经选中的数据，若有，则清除属于当前节点下的数据
      //   // 若没有选中的数据，则不做处理
      //   if (this.checkedList.length > 0) {
      //     this.checkedList = this.checkedList.filter((item) => {
      //       return item.id !== data.id;
      //     });
      //   }
      //   // 判断取消选中的是不是当前展示的表格，
      //   if (this.categoryId === data.id) {
      //     // 若取消的是当前展示的表格，则清除所有的选中项
      //     that.$nextTick(() => {
      //       that.$refs.configDataRef.clearSelection()
      //     })
      //   }
      //   this.$emit('closeLoading')
      // }
      // console.log('----结束-------', this.$refs.treeDataRef.getCheckedKeys());
    },
    // 当树节点的复选框被点击时触发
    treeCheck (data, node) {
      this.$emit('loading')
      // console.log('点击复选框---data---', data.name, '****', data.id);
      // console.log('点击复选框--node---', node.checkedKeys);
      // console.log('------------------------------------------');
      // 展开当前点击的树控件
      this.defaultExpandedKeys.push(data.id)
      this.getDefaultExpandedKeys(data.children)
      this.defaultExpandedKeys = [...new Set(this.defaultExpandedKeys)]
      // 获取当前节点下的所有子节点
      var childKey = []
      this.getChild(data.children, childKey)
      // console.log('childKey--------', childKey);
      // 获取所有选中的节点
      var checkey = node.checkedKeys
      // 1、判断 所有选中的节点 是否包含 当前节点
      // 2、若不包含，则表示为取消选中当前节点
      // 3、若包含，则表示选中当前节点
      var index = checkey.findIndex(item => item === data.id)
      if (index < 0) { // 取消选中
        childKey.push(data.id)
        childKey.forEach(item => {
          this.getChildItem(false, item)
        })
        var newArr = []
        checkey.forEach(item => {
          if (childKey.indexOf(item) === -1) {
            newArr.push(item)
          }
        })
        this.$refs.treeDataRef.setCheckedKeys(newArr)
      } else { // 选中
        // checkey.splice(index, 1);
        checkey = checkey.concat(childKey)
        this.$refs.treeDataRef.setCheckedKeys(checkey)
        this.getChildItem(true, data.id)
        childKey.forEach(item => {
          this.getChildItem(true, item)
        })
      }
    },
    // 获取子节点
    getChild (data, arr) {
      data.forEach(item => {
        arr.push(item.id)
        if (item.children && item.children.length > 0) {
          this.getChild(item.children, arr)
        }
      })
    },
    // 获取子节点中的巡检项
    async getChildItem (check, id) {
      var that = this
      this.isProject = this.$refs.treeDataRef.getNode(id).data.is_project
      if (check) {
        if (this.categoryId === id) {
          // 若选中的是当前展示的表格，则选中所有的选中项
          if (this.configData.length > 0) {
            that.$nextTick(() => {
              that.$refs.configDataRef.toggleAllSelection()
            })
          }
        } else {
          // 若选中的不是当前展示的表格，则获取当前节点下巡检项并全选
          this.configData = await this.fnGetAssetInspectionItemList(id)
          this.categoryId = id
          // 将当前获取的巡检项数据保存已选中数据列表中
          this.checkedList.push({
            id: this.categoryId,
            list: this.configData
          })
          // 设置全选状态
          that.$nextTick(() => {
            that.$refs.configDataRef.toggleAllSelection()
          })
        }
      } else {
        // 1、判断是否有已经选中的数据，若有，则清除属于当前节点下的数据
        // 若没有选中的数据，则不做处理
        if (this.checkedList.length > 0) {
          this.checkedList = this.checkedList.filter((item) => {
            return item.id !== id
          })
        }
        // 判断取消选中的是不是当前展示的表格，
        if (this.categoryId === id) {
          // 若取消的是当前展示的表格，则清除所有的选中项
          that.$nextTick(() => {
            that.$refs.configDataRef.clearSelection()
          })
        }
      }
      this.$emit('closeLoading')
    },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      console.log(val)
      // 获取所有选中的树节点
      var list = this.$refs.treeDataRef.getCheckedKeys()
      // 当选择项有数据时
      if (val.length > 0) {
        // 判断是否有已经选中的数据，若有，则清除属于当前节点categoryId下的数据
        if (this.checkedList.length > 0) {
          this.checkedList = this.checkedList.filter((item) => {
            return item.id !== this.categoryId
          })
        }
        // 将当前选中的数据添加到选中数据列表中
        this.checkedList.push({
          id: this.categoryId,
          list: val
        })
        if (!list.includes(this.categoryId)) {
          this.$refs.treeDataRef.setChecked(this.categoryId, true, false)
        }
      } else {
        // 若有选中的树节点，
        if (list.length > 0) {
          // 则判断是否存在当前展示巡检项节点的数据
          if (list.includes(this.categoryId)) {
            // 若存在，则消除选中数据列表中的所有当前节点的巡检项数据
            this.checkedList.forEach((item) => {
              if (item.id === this.categoryId) {
                item.list = []
              }
            })
          }
        }
      }
    },
    // 关闭对话框时触发
    configImportDialogClose () {
      // console.log('关闭对话框时触发---');
      this.$emit('myClose')
      this.$emit('closeLoading')
      this.isConfigImportDialog = false
    },
    sendSave () {
      this.$emit('loading')
      var arr = this.deepClone(this.treeData)
      // console.log('需要处理的数据**---', this.checkedList);
      // console.log('树结构---', JSON.stringify(arr));
      var newArr = []
      // 1、遍历选中巡检项数据列表
      this.checkedList.forEach((item) => {
        // 2、遍历选中节点下的巡检项数据，将巡检项数据处理成要传给接口数据格式
        if (item.list.length > 0) {
          var list = []
          item.list.forEach((i) => {
            list.push({
              inspection_name: i.inspection_name,
              inspection_content: i.inspection_content,
              pic: i.pic
            })
          })
          item.list = list
        }
        // 3、遍历树控件，将巡检项数据添加到对应的节点下，并设置字段标识
        newArr = this.fnGetCheckedTreeList(arr, item)
      })
      console.log('3------', newArr)
      // 4、根据树控件中的字段标识，筛选出需要的数据
      newArr = this.deepFilterTreeList(newArr, (node) => {
        return node.flag
      })
      console.log('4------', newArr)
      // 5、遍历筛选的数据，将数据处理成要传给接口数据格式
      var data = this.setData(newArr, [])
      console.log('5----', data)
      console.log(this.childLevel)
      if (this.childLevel < 10) {
        // 6、请求后台接口
        this.fnImportConfiguration({
          Data: data,
          Id: this.bindId,
          is_project: false,
          Name: this.bindName
        })
      } else {
        this.$msg.warning('已超过层级限制，请重新选择')
        this.$emit('closeLoading')
      }
    },
    // 遍历筛选的数据，将数据处理成要传给接口数据格式
    setData (data, newArr) {
      var that = this
      data.forEach((item) => {
        var newChildren = []
        if (item.children.length > 0) {
          newChildren = this.setData(item.children, [])
          that.childLevel++
        }
        newArr.push({
          category_name: item.name,
          list_inspection: item.list,
          Children: newChildren
        })
      })
      return newArr
    },
    // 导入数据请求
    async fnImportConfiguration (obj) {
      const res = await importConfiguration(obj)
      console.log(res)
      if (res && res.Code === 200) {
        this.configImportDialogClose()
      }
      this.$emit('closeLoading')
    },
    // 根据树控件中的字段标识，筛选出需要的数据
    /**
     *
     * @param {*} tree 传入数组
     * @param {*} fn 过滤方法，传入当前节点，返回为ture的数据
     * @param {*} children 子节点字段
     * @returns
   */
    deepFilterTreeList (tree, fn, children = 'children') {
      const getChildList = (list, fn, children) => {
        const resList = []
        for (const node of list) {
          if (fn(node)) {
            resList.push(node)
          } else {
            if (node[children] && node[children].length > 0) {
              const array = getChildList(node[children], fn)
              resList.push(...array)
            }
          }
        }
        return resList
      }
      const deepfilter = (tree, fn) => {
        if (!tree) {
          return []
        }
        const nodes = JSON.parse(JSON.stringify(tree))
        // 如果数组不存在，返回空
        if (!(nodes && nodes.length)) {
          return []
        }
        const newChildren = []
        for (const node of nodes) {
          if (!fn(node) && node[children] && node[children].length > 0) {
            const array = getChildList(node[children], fn, children)
            nodes.push(...array)
          }
        }
        for (const node of nodes) {
          if (fn(node)) {
            // 递归过滤
            node[children] = deepfilter(node[children], fn, children)
            // 父节过滤成功，添加到数组
            newChildren.push(node)
          }
        }
        return newChildren.length ? newChildren : []
      }
      return deepfilter(tree, fn)
    },
    // 遍历树控件，将巡检项数据添加到对应的节点下，并设置字段标识
    fnGetCheckedTreeList (data, check) {
      data.forEach((item) => {
        if (item.id === check.id && !item.is_project) {
          console.log(item)
          item.list = check.list
          item.flag = true
        }
        if (item.children.length > 0) {
          this.fnGetCheckedTreeList(item.children, check)
        }
      })
      console.log(data)
      return data
    }
  }
}
</script>

<style scoped lang="less">
.title {
  width: 100%;
  font-weight: 700;
  text-align: left;
  color: #000;
  margin-bottom: 10px;
}
.content_box {
  display: flex;
  width: 100%;
  .left_box {
    width: 40%;
    /deep/ .el-tree {
      overflow: auto;
      max-height: 500px;
    }
  }
  .vertical_box {
    height: inherit;
    padding: 0 10px;
    .el-divider--vertical {
      height: 100%;
    }
  }
  .right_box {
    width: 55%;
  }
}
.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 420px;
    overflow-y: auto;
  }
}
</style>
